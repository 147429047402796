import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "editor", "display", "hiddenField"]

  connect(){
    this.highlightAndSaveText()
  }

  highlightAndSaveText(){
    const matches = [...this.editorTarget.innerHTML.matchAll(/\{\{([^}]*)\}\}/g)]

    const variableList = JSON.parse(this.element.dataset.variables)

    let newDisplayText = this.editorTarget.innerHTML
    let newHiddenField = this.editorTarget.innerText
    matches.forEach(match => {
      const variableClass = (variableList[match[1]]) ? 'variable-good' : 'variable-wrong'
      newDisplayText = newDisplayText.replaceAll(match[0], `<span class='${variableClass}'>${match[0]}</span>`)
      if (variableList[match[1]]){
        newHiddenField = newHiddenField.replaceAll(match[0], `{{${variableList[match[1]]}}}`)
      }
    })
    this.displayTarget.innerHTML = newDisplayText
    this.hiddenFieldTarget.value = newHiddenField
  }

  focusEditor(){
    this.wrapperTarget.classList.add('highLite-focus')
  }

  unfocusEditor(){
    this.wrapperTarget.classList.remove('highLite-focus')
  }

  addVariable(){
    // Get variable text
    let variable = event.currentTarget.innerText;

    // Get current rich text
    // Browsers sometimes include automatically a <br> tag at the end of the content that we need to remove
    // We add also if needed a space before the variable
    this.editorTarget.innerHTML = this.editorTarget.innerHTML.replace(/<br\s*\/?>\s*$/i, '')
    const richText = this.editorTarget.innerText;
    if (richText.charAt(richText.length - 1) !== ' ') variable = ` ${variable}`

    // Créer un nœud texte avec le texte à insérer
    const textNode = document.createTextNode(variable);

    // Ajouter le texte à la fin de l'élément contenteditable
    this.editorTarget.appendChild(textNode);

    // Déplacer le curseur à la fin de l'élément contenteditable
    const range = document.createRange();
    const selection = window.getSelection();

    // Placer le range à la fin du nœud
    range.setStart(textNode, textNode.length);
    range.collapse(true);

    // Vider les anciennes sélections et ajouter la nouvelle
    selection.removeAllRanges();
    selection.addRange(range);

    // Donner le focus à l'élément contenteditable pour permettre la saisie
    this.editorTarget.focus();

    this.highlightAndSaveText()
  }
}