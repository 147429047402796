import {Controller} from "@hotwired/stimulus";
import cytoscape from "cytoscape";
import { removeContent, projectName } from "../../components/utilities";
import setTooltips from '../../components/app_tooltips';
import PieController from 'chart.js/auto';

import ChartJs from "../../components/initialize_graph";

import coseBilkent from 'cytoscape-cose-bilkent';
cytoscape.use( coseBilkent );

export default class extends Controller {

  static targets = ["graph", "loadPanel", "controlPanel", "rightPanel", "hideTrendsButton",
                    "noDataMessage", "table", "zoomBtns", "legendTrend", "remainder",
                    "canvas", "evolutionChart", "distributionChart"];

  // Event handlers
  reverseScaleUpdateHandler =  (event) => this.reverseScale()
  switchTableMapHandler = (event) => this.changeView()
  metriRangeUpdateHandler = (event) => this.changeMetricRange()
  filterDataUpdateHandler = (event) => this.updateComparison(event.detail.data)

  connect() {
    // Events
    window.addEventListener('reverse-scale-updated', this.reverseScaleUpdateHandler)
    window.addEventListener('switch-table-map', this.switchTableMapHandler)
    window.addEventListener('metric-range-updated', this.metriRangeUpdateHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdateHandler)

    // Determine the hideTrends mode
    this.hideTrends = this.hideTrendsButtonTarget.dataset.value === 'true';

    // Determine the reverseScale mode
    this.reversedScale = this.element.dataset.reversedScale === 'true';

    // AJAX call to get the comparison
    setTimeout(() => {
      const launchComparisonEvent = new CustomEvent("launch-comparison");
      window.dispatchEvent(launchComparisonEvent);
    }, 600) // Need to wait the time that the filter bar becomes active (500)
  }

  disconnect() {
    // Events
    window.removeEventListener('reverse-scale-updated', this.reverseScaleUpdateHandler)
    window.removeEventListener('switch-table-map', this.switchTableMapHandler)
    window.removeEventListener('metric-range-updated', this.metriRangeUpdateHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdateHandler)
  }

  drawPieChart(node, categories){
    if(categories === undefined) return

    let image = ''

    let myChart = new PieController(document.getElementById('chart').getContext("2d"), {
      type: 'pie',
      data: {
        datasets: [{
          data: node["pie_values"],
          backgroundColor: node["pie_colors"]
        }]
      },
      options: {
        animation: false,
        elements: {
          arc: {
              borderWidth: 0
          }
        }
      }
    });

    // Get the chart's base64 image string
    image = myChart.toBase64Image();
    myChart.destroy();

    return image;
  }

  // UPDATING FUNCTIONS FOR THE PAGE_____________________________________________________________________________________________

  updateComparison(data){
    this.resetPanels();

    // If a cluster is selected (grayed table row), save it to hightlight the node
    const selectedCluster = Array.from(this.tableTarget.querySelectorAll('tr')).filter(tr => tr.classList.contains('bg-gray-100'));
    const selectedId = selectedCluster.length === 0 ? null : selectedCluster[0].id.replace('clusters-', '');

    Object.keys(data).forEach(component => {
      switch (component) {
        case 'graph_data':
          this.updateGraph(data['graph_data'], data['perimeters']);
          break;
        case 'control_panel':
          this.updateControlPanel(data['control_panel']);
          break;
        case 'cluster_panel':
          this.updateClusterTable(data['cluster_panel']);
          break;
        case 'no_data':
          this.showNoDataMessage();
        case 'perimeters':
        this.perimeters = data['perimeters'] // To serve right_panel
      }
    })

    this.hightlightClusterAfterUpdate(selectedId)
  }

  // Update graph after filters changed
  updateGraph(graphData, categories) {
    // Hide the loader
    this.graphTarget.classList.remove('hidden');

    this.initializePieGraph(graphData["elements"], categories);

    // Initialize layout options
    let layoutOptions = {
      name: "cose-bilkent",
      tile: false,
      randomize: false,
      padding: 0,
      nodeDimensionsIncludeLabels: false,
      edgeElasticity: 0.01,
      numIter: 2500,
      initialEnergyOnIncremental: 10,
      idealEdgeLength: 30,
      nodeRepulsion: 100,
      nestingFactor: 1,
      stop: () => {
        // Add interaction with the graph
        this.addGraphListener();
        this.updateClusterVisibility(); // For range & trend mode
      }
    }

    // Display the graph
    this.cy.resize();
    this.layout = this.cy.layout(layoutOptions);
    this.layout.run();
  }

  updateControlPanel(controlPanelHTML){
    // Show control panel
    removeContent(this.controlPanelTarget)
    this.controlPanelTarget.insertAdjacentHTML('beforeend', controlPanelHTML);
    this.loadPanelTarget.classList.add('hidden');
    this.controlPanelTarget.classList.remove('hidden');
    // Load charts
    this.loadChart('control-evolution-chart', this.hideTrends)
    this.loadChart('control-distribution-chart', this.hideTrends)
  }

  updateClusterTable(clusterTableHTML){
    // Show cluster table
    removeContent(this.tableTarget)
    this.tableTarget.insertAdjacentHTML('beforeend', clusterTableHTML)

    // Initialize tooltips
    setTooltips(this.tableTarget);
  }


  showNoDataMessage(){
    this.noDataMessageTarget.classList.remove('hidden');
    this.loadPanelTarget.classList.remove('hidden');
    this.controlPanelTarget.classList.add('hidden');
    this.tableTarget.classList.add('hidden');
  }

  hightlightClusterAfterUpdate(selectedId){
    if (selectedId === null) return;

    const node = this.cy.nodes().filter(node => node.data('cluster_id') === parseInt(selectedId,10))[0];
    this.hightlightNode(node);
    // We send an AJAX request to get info to display on the right panel
    this.getRightPanel(node);
    // We hightlight the selected row
    this.hightlightRow(node.data('cluster_id'))
  }

  // END OF UPDATING FUNCTION FOR THE PAGE_____________________________________________________________________________________________

  initializePieGraph(data, categories) {
    let style = [
      {
        selector: 'node',
        wheelSensitivity: 0.01,
        style: {
          'label': 'data(cluster_name)',
          'text-halign': 'center',
          'text-valign': 'center',
          'font-size': 15,
          'min-zoomed-font-size': 10,
          'background-opacity': 0
        }
      },
      {
        selector: 'node.trends',
        style: {
            'background-opacity': '0.2',
            'border-width': '8%',
            'border-color': '#133c55',
            'border-style': 'double'
        }
      },
      {
        selector: 'edge',
        style: {
          'line-color': '#133c55',
          'width': 1,
          'opacity': '1'
        }
      },
      {
        selector: 'node.selected',
        style: {
          'background-opacity': '1',
          'border-width': '8%',
          'border-color': '#133c55'
        }
      },
      {
        selector: 'node.semitransp',
        style: {
          'background-opacity': 0
        }
      }
    ];

    // Initialize the new graph
    this.cy = cytoscape({
      container: this.graphTarget,
      style: style,
      elements: data,
      wheelSensitivity: 0.05
    });

    const impacts = this.cy.nodes().map(node => node.data('metric'))
    this.findExtremes(impacts)
    this.refreshMetricRange()

    // Update size of each node
    const sortedNodesPerMetric = this.cy.nodes().sort( (a,b) => ( (a.data('metric')) > (b.data('metric')) ? -1 : 1 ) );
    sortedNodesPerMetric.forEach(node => {
      const size = this.setNodeSize(node.data('metric'), node.data('validated'), node.data('minimized'), false)
      node.css('background-image', this.drawPieChart(node.data(), categories));
      node.css('background-fit', 'contain')
      node.css('width', size);
      node.css('height', size);
      node.css(this.fontStyle(node));
    });
  }


  // Find min and max of the impact
  findExtremes(array){
    this.maxMetric = Math.max(...array)
    this.minMetric = Math.min(...array)
  }


  // Will trigger the action resetMetricRange in metric_options_controller
  refreshMetricRange(){
    const range = [Math.floor(this.minMetric), Math.ceil(this.maxMetric)]
    this.metricRange = range;
    const customEvent = new CustomEvent("reset-metric-range", { detail: range });
    window.dispatchEvent(customEvent);
  }

  // Set the size of the bubbles based on a normalization
  setNodeSize(metric, clusterValidated, clusterMinimized, initMode){
    let maxBubbleSize = 500;
    let minBubbleSize = 50;

    let size;

    // Hide the bubbles by changing the size to 0 for those nodes that are either not validated for micro and Validated Only = on, either not part of the filter results
    if (!initMode && ((!clusterValidated && this.hideTrends) || metric === undefined )){
      return 0;
    }

    // If the cluster metric is outside the defined metric range, set the size to 0
    if (!initMode && (metric < this.metricRange[0] || metric > this.metricRange[1])){
      return 0;
    }

    // If the cluster is minimized, set the size to the minimum
    if (clusterMinimized){
      return 50;
    }

    if (this.maxMetric === this.minMetric){
      // If min = max --> All nodes have the same impact, so arbitrary, we set a size to 100
      size = minBubbleSize;
    } else{
      if (this.reversedScale){
        size = (this.maxMetric - metric)*(maxBubbleSize - minBubbleSize)/(this.maxMetric - this.minMetric) + minBubbleSize;
      } else {
        size = (metric - this.minMetric)*(maxBubbleSize - minBubbleSize)/(this.maxMetric - this.minMetric) + minBubbleSize;
      }
    }

    return size;
  }

  // Set font style of node labels
  fontStyle(node){
    return {
      'font-size': node._private.style.width.value*0.15,
      'color': node.data('minimized') ? 'gray' : 'black',
      'font-style': node.data('minimized') ? 'italic' : 'normal'
    }
  }

  updateClusterVisibility(){
    this.cy.nodes().forEach(node => {
      // Check if validated
      const isValidated = node.data('validated')
      if (!isValidated) node.addClass('trends')

      // Check if in metric range
      const metric = node.data('metric')
      const isInMetricRange = (metric >= this.metricRange[0] && metric <= this.metricRange[1])

      const row = this.tableTarget.querySelector(`tr#clusters-${node.data('cluster_id')}`)
      if (!row) return;

      let size
      if ((this.hideTrends && !isValidated) || (!isInMetricRange)){
        size = 0
        row.classList.add('hidden')
      } else {
        size = this.setNodeSize(node.data('metric'), node.data('validated'), node.data('minimized'), true)
        row.classList.remove('hidden')
      }

      this.resizeNode(node, size);

      setTimeout(() => {
        node.style(this.fontStyle(node));
      }, 450)
    })
  }

  resizeNode(node, size=0) {
    node.animate({
        style: { width: size, height: size },
        duration: 400,
        easing: 'ease-in-sine'
    })
  }



  // SHOW TRENDS__________________________________________________________________________________________________________

  toggleTrends(){
    this.hideTrends = !this.hideTrends;
    event.currentTarget.dataset.value = this.hideTrends;

    // Show/Hide legend
    (this.hideTrends) ? this.legendTrendTarget.classList.add('hidden') : this.legendTrendTarget.classList.remove('hidden')

    // Update chart in control panel
    this.loadChart('control-evolution-chart', this.hideTrends)
    this.loadChart('control-distribution-chart', this.hideTrends)
    
    this.updateClusterVisibility()
  }

  // END SHOW TRENDS______________________________________________________________________________________________________




  // GRAPH INTERACTIVITY________________________________________________________________________________________________

  zoomIn(){
    const currentZoom = this.cy.zoom();
    this.cy.zoom(currentZoom + 0.05);
  }

  // Zoom out button
  zoomOut(){
    const currentZoom = this.cy.zoom();
    this.cy.zoom(currentZoom - 0.05);
  }

  addGraphListener() {
    this.cy.on('tap', event => {

      var evtTarget = event.target;
      // If we click on the background of the graph
      if (evtTarget === this.cy) {
        this.cleanFront();
        // If we click on a node
      } else {
        if (evtTarget.group() === 'nodes') { // We check if the the object is a node (if not, we do nothing)
          // We hightlight the node
          this.hightlightNode(evtTarget);
          this.hightlightRow(evtTarget.id());
          // We send an AJAX request to get info to display on the right panel
          this.getRightPanel(evtTarget);
        }
      }
    });
  }

  // Highlight a node
  // Used by addGraphListener()
  hightlightNode(selectedNode) {
    // We remove the former highlighted node
    this.cy.elements().removeClass('semitransp');
    this.cy.elements().removeClass('selected');

    // We add the new highlighted node
    selectedNode.addClass('selected');
    this.cy.elements().not(selectedNode).addClass('semitransp');
  }

  // Reset the graph to its original format
  cleanFront() {
    // We reinitialize the graph
    this.cy.elements().removeClass('semitransp');
    this.cy.elements().removeClass('highlight');
    this.cy.elements().removeClass('selected');
    this.resetPanels();

    // We reinitialize the table view
    this.tableTarget.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-100'))
  }

  // Reset right panel before adding new informations
  resetPanels() {
    this.noDataMessageTarget.classList.add('hidden');
    removeContent(this.rightPanelTarget);
    this.rightPanelTarget.classList.add('hidden');
    this.controlPanelTarget.classList.remove('hidden');
  }


  // Triggered by custom event: "metric-range-updated"
  changeMetricRange(){
    const newMetricRange = event.detail.range;

    clearTimeout(this.metricTimeout)
    this.metricTimeout = setTimeout(() => {
      this.metricRange = newMetricRange
      this.updateClusterVisibility()
    }, 1000)
  }

  // Triggered by custom event: "reverse-scale-updated"
  reverseScale(){
    this.reversedScale = event.detail.reversed;
    this.updateClusterVisibility()
    setTimeout(() => {
      this.cy.removeAllListeners()
      this.layout.run()
    }, 450)
  }

  // END GRAPH INTERACTIVITY____________________________________________________________________________________________





  // RIGHT PANEL________________________________________________________________________________________________________

  // get extra cluster informations using Ajax
  // return right_panel html content
  getRightPanel(node) {
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/perimeters/right_panel`;
    const filters = JSON.parse(document.querySelector('[data-controller="filter-bar"]').dataset.filters)
    let body = {
      cluster_id: node.data('cluster_id'),
      evolution: node.data('evolution'),
      filters: filters,
      perimeters: this.perimeters
    };

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
      .then(response => response.text())
      .then(data => {
        this.rightPanelTarget.innerHTML = data;

        this.loadChart("right-panel-evolution-graph");
        this.loadChart("right-panel-distribution-graph");
        this.controlPanelTarget.classList.add('hidden');
        this.rightPanelTarget.classList.remove('hidden');

        // Set the tooltips
        setTooltips(this.element);
      });
  }

  closePanel(){
    this.cleanFront();
  }

  // Load selected graph using Chart JS
  loadChart(selector, validatedOnly=false) {
    const chartContainer = document.querySelector(`#${selector}`);
    const chartDataset = JSON.parse(chartContainer.dataset.chart)
    const chartData = (validatedOnly) ? chartDataset.validated : chartDataset.all
    const historyChart = new ChartJs(chartContainer, chartData);
    historyChart.drawChart();
  }

  // END RIGHT PANEL____________________________________________________________________________________________________





  // TABLE INTERACTIONS_________________________________________________________________________________________________

  // Change views between table and graph
  changeView(){
    const state = event.detail.state

    if (state === 'table'){
      // Change the view
      this.tableTarget.classList.remove("hidden");
      this.zoomBtnsTarget.classList.add("hidden");
    }else {
      // Change the view
      this.tableTarget.classList.add("hidden");
      this.zoomBtnsTarget.classList.remove("hidden");
    }
  }

  // Get right panel from the list
  selectRow(){
    const selectedId = event.currentTarget.id.replace("clusters-", "");
    const node = this.cy.nodes().filter(node => node.data('cluster_id') === parseInt(selectedId,10))[0]
    this.getRightPanel(node)
    // We hightlight the selected node (when we close the table view, we need to seed the selected node)
    this.hightlightNode(node)
    // We hightlight the selected row
    this.hightlightRow(node.data('cluster_id'))
  }

  hightlightRow(clusterId){
    this.tableTarget.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-100'))
    const selectedRow = this.tableTarget.querySelector(`tr[id='clusters-${clusterId}']`)
    selectedRow.classList.add('bg-gray-100')
  }

  // END TABLE INTERACTIONS_____________________________________________________________________________________________
}
